import React, { useState, useEffect, useContext } from "react";
import { MetroSpinner } from "react-spinners-kit";
import { FiSearch } from "react-icons/fi";
import NftSingle from "./NftSingle";
import { HaschconnectContext, instance } from "../../App";

function HederaNFTGrid(props) {

  const [weaponCids, setWeaponCids] = useState([]);
  const [currentNfts, setCurrentNfts] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [namesearch, setNameSearch] = useState("");
  const [contentsearch, setContentSearch] = useState("");
  const [selectPriceType, setSelectPriceType] = useState("");

  const { hash, minted, setMinted, weaponNfts, setWeaponNfts } = useContext(HaschconnectContext);

  useEffect(() => {
    if (weaponCids.length > 0) {
      return;
    }
    else {
      getCids()
    }
  })

  // Get All nfts on Owner Wallet
  async function getCids() {
    let nftaccounts = await instance.get(`accounts/${process.env.REACT_APP_OWNERID}/nfts`);
    let nft = nftaccounts.data.nfts;
    let metadata = []
    console.log("nftlength " + nft.length);
    for (var i = 0; i < nft.length; i++) {
      const currentvalue = nft[i].metadata;
      const newValue = Buffer.from(currentvalue, "base64");
      const res = await fetch(`https://ipfs.io/ipfs/${newValue}`);
      const meta = await res.json();
      meta.serial_number = nft[i].serial_number.toString();
      metadata.push(meta);
      console.log(i);
    }
    console.log("Metadata: ", metadata )
    setWeaponCids(metadata);
    setCurrentNfts(metadata)
  }

  //Marketplace NFTs list filter
  function handleNameSearch(e) {
    setNameSearch(e.target.value)
  }

  function handleNameSearchSubmit() {
    console.log("handleNameSearchSUbmut", namesearch)
    let nfts = [];
    for (var i = 0; i < weaponCids.length; i++) {
      let text = weaponCids[i].name.toLowerCase();
      let position = text.search(namesearch.toLowerCase());
      console.log(position)
      if (position >= 0) {
        console.log("position")
        if (contentsearch === "") {
          console.log(selectType)
          if (selectType === "All" || selectType === "") {
            nfts.push(weaponCids[i])
          } else if (weaponCids[i].weaponType === selectType) {
            nfts.push(weaponCids[i])
          }
        } else {
          let content = weaponCids[i].description.toLowerCase();
          let contentposition = content.search(contentsearch.toLowerCase());
          if (contentposition >= 0) {
            if (selectType === "All" || selectType === "") {
              nfts.push(weaponCids[i])
            } else if (weaponCids[i].weaponType === selectType) {
              nfts.push(weaponCids[i])
            }
          }
        }
      }

    }
    setCurrentNfts(nfts)
  }

  function handleContentSearch(e) {
    setContentSearch(e.target.value)
  }

  function handleContentSearchSubmit() {
    console.log("handleContentSearchSUbmit")
    let nfts = [];
    for (var i = 0; i < weaponCids.length; i++) {
      let text = weaponCids[i].description.toLowerCase();
      let position = text.search(contentsearch.toLowerCase());
      if (position >= 0) {
        if (namesearch === "") {
          if (selectType === "All" || selectType === "") {
            nfts.push(weaponCids[i])
          } else if (weaponCids[i].weaponType === selectType) {
            nfts.push(weaponCids[i])
          }

        } else {
          let content = weaponCids[i].name.toLowerCase();
          let contentposition = content.search(namesearch.toLowerCase());
          if (contentposition >= 0) {
            if (selectType === "All" || selectType === "") {
              nfts.push(weaponCids[i])
            } else if (weaponCids[i].weaponType === selectType) {
              nfts.push(weaponCids[i])
            }

          }
        }
      }
    }
    setCurrentNfts(nfts)
  }

  function handleSelectTypeChange(event) {
    let nfts = [];
    setNameSearch("")
    setContentSearch("")
    for (var i = 0; i < weaponCids.length; i++) {
      if (event.target.value === "All") {
        nfts.push(weaponCids[i])
      } else if (weaponCids[i].weaponType === event.target.value) {
        nfts.push(weaponCids[i])
      }
    }
    setSelectType(event.target.value)
    setCurrentNfts(nfts)
  }

  function handlePriceSelectTypeChange(event) {
    if (event.target.value === "1")
      setCurrentNfts(weapons => [...weapons].sort((a, b) => a.price - b.price));
    else
      setCurrentNfts(weapons => [...weapons].sort((a, b) => b.price - a.price));

    setSelectPriceType(event.target.value)
  }


  return (
    <section className="mt-5 py-15  md:mx-40 sm:mx-20 mx-10 sm:mt-10 sm:py-10 ">
      {/* filter */}
      <div
        className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 border-b border-primary-light dark:border-gray-700 justify-betweenpb-3">
        <div className="flex w-full pl-7 mb-2 md:mb-5">
          <input
            className="font-general-medium w-full font-sans md:text-sm border border-slate-950 rounded-lg sm:text-[5px]	bg-secondary-light text-primary-dark focus:outline-none w-1/4 pl-4"
            id="nftSearch"
            name="nftSearch"
            type="search"
            required=""
            placeholder="SEARCH NFT NAME..."
            aria-label="Name"
            value={namesearch}
            onChange={handleNameSearch}
            style={{ border: "1px solid black", height: "45px" }}
          />
          <span
            className="sm:block bg-primary-light dark:bg-ternary-dark p-2.5 shadow-sm  rounded-xlP cursor-pointer"
            onClick={handleNameSearchSubmit}
            style={{ marginLeft: "-52px", marginTop: "-11px" }}>
            <FiSearch className="dark:text-white h-10 text-ternary-dark w-8 mt-1" style={{ color: "#c5c6c6" }}></FiSearch>
          </span>
        </div>
        <div className="flex w-full pl-7 mb-2 md:mb-5">
          <input
            className="font-general-medium w-full font-sans text-sm border border-slate-950 rounded-lg sm:text-md bg-secondary-light text-primary-dark focus:outline-none w-1/4 pl-4"
            id="nftSearch"
            name="nftSearch"
            type="search"
            required=""
            placeholder="SEARCH NFT CONTENT..."
            aria-label="Name"
            value={contentsearch}
            onChange={handleContentSearch}
            style={{ border: "1px solid black", height: "45px" }}
          />
          <span
            className="sm:block bg-primary-light dark:bg-ternary-dark p-2.5 shadow-sm  rounded-xlP cursor-pointer"
            onClick={handleContentSearchSubmit} style={{ marginLeft: "-52px", marginTop: "-11px" }}>
            <FiSearch className="dark:text-white h-10  w-8 mt-1" style={{ color: "#c5c6c6" }}></FiSearch>
          </span>
        </div>
        <div className="flex w-full pl-7 pb-2.5 mb-2 md:mb-5">
          <select
            className="font-general-medium font-sans text-sm border border-slate-950 rounded-lg sm:text-md bg-secondary-light text-primary-dark focus:outline-none w-full pl-4"
            style={{ backgroundImage: "url('https://marketplace.digital-arms.com/img/ep_arrow-right-bold.png')", backgroundRepeat: "no-repeat", backgroundPosition: "95% 50%", appearance: "none", border: "1px solid black", height: "45px" }}
            value={selectPriceType}
            onChange={handlePriceSelectTypeChange}
          >
            <option className="sm:text-md text-normal" value="0">PRICE: LOW TO HIGHT</option>
            <option className="sm:text-md text-normal" value="1">PRICE: HIGH TO LOW</option>
          </select>
        </div>
        <div className="flex w-full pl-7 mb-2 md:mb-5">
          <select
            className="font-general-medium  font-sans text-sm border border-slate-950 rounded-lg sm:text-md bg-secondary-light text-primary-dark focus:outline-none w-full pl-4" style={{ backgroundImage: "url('https://marketplace.digital-arms.com/img/ep_arrow-right-bold.png')", backgroundRepeat: "no-repeat", backgroundPosition: "95% 50%", appearance: "none", border: "1px solid black", height: "45px" }}
            value={selectType}
            onChange={handleSelectTypeChange}
          >
            <option className="sm:text-md text-normal" value="All">ALL ITEMS</option>
            <option className="sm:text-md text-normal" value="Primary">PRIMARY</option>
            <option className="sm:text-md text-normal" value="Secondary">SECONDARY</option>
            <option className="sm:text-md text-normal" value="Special">SPECIAL</option>
            <option className="sm:text-md text-normal" value="Melee">
              MELEE{" "}
            </option>
            <option className="sm:text-md text-normal" value="Explosive">EXPLOSIVE</option>
          </select>
        </div>
      </div>
      {/* NFTS list */}
      {console.log(currentNfts)}
      <div className="grid grid-cols-1  lg:grid-cols-2 md:grid-cols-1 xl:grid-cols-3 2xl:grid-cols-4 mt-6 p-9 sm:gap-10 gap-10 ">
        {currentNfts.length > 0 ? (
          currentNfts.map((metadata, index) => (
            <HaschconnectContext.Provider value={{ hash, minted, setMinted, weaponNfts, setWeaponNfts }} key={index}>
              <NftSingle
                creator={"@BrainStorm"}
                name={metadata.name}
                cid={metadata.cid}
                image={metadata.image.slice(7)}
                description={metadata.description}
                accountId={props.accountId}
                topic={props.topic}
                price={metadata.price}
                serialNumber={metadata.serial_number}
                setCurrentNfts={setCurrentNfts}
              />
            </HaschconnectContext.Provider>
          ))
        ) : (
          // Spinner
          <div className="flex justify-center mt-10 w-full">
            <MetroSpinner color="#00ff89" />
          </div>
        )}
      </div>
    </section>
  );
}
export default HederaNFTGrid;