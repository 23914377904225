import React from "react";
import { Link } from "react-router-dom"
import ReactPlayer from "react-player/youtube";

import Kommando from "../../assets/images/div.tp-bgimg.png";
import Section from "../../assets/images/Section.png";
import Section1 from "../../assets/images/bg1.png";
import Vector from "../../assets/images/Vector.png";
import heroScreenshotImageSrc from "../../assets/images/Vector (1).png";
import BackGround from "../../assets/images/Background.png";
import TeamIllustrationSrc from "../../assets/images/Y7 1.png";
import TeamIllustrationSrc1 from "../../assets/images/677 1.png";
import TeamIllustrationSrc2 from "../../assets/images/676 1.png";
import TeamIllustrationSrc3 from "../../assets/images/897 1.png";
import Rectangle from "../../assets/images/Rectangle 13.png";
import Headra from "../../assets/images/hedera-hbar-logo 2.png";
import Logo_Oculus from "../../assets/images/Logo_Oculus_horizontal 1.png";
import Epic_Games_logo from "../../assets/images/Epic_Games_logo 1.png";
import Steam_icon_logo from "../../assets/images/Steam_icon_logo 1.png";
import path from "../../assets/images/path-1.png";
import g4146 from "../../assets/images/g4146.png";

const Landing = () => {

  return (
    <div>
      <img src={Kommando} style={{ height: "1030" }} className="w-full" alt="KOMMANDO LOGO" />

      <div className="text-center space-x-4 flex sm:mt-0 mt-5">
        <button
          style={{ fontFamily: "MyCustomFont", marginTop: "-30px" }}
          className="text-sm sm:text-2xl lg:text-2xl bg-black text-white hover:bg-blue-400 font-bold sm:pr-16 sm:pl-16 pr-8 pl-8 py-2 px-4 rounded mx-auto"
        >
          PRE-ORDER NOW
        </button>
      </div>
      <div
        className="pt-8 px-8 mt-5 relative sm:pb-16 lg:pb-16  bg-cover"
        style={{ backgroundImage: `url(${Section})` }}
      >
        <div className="max-w-screen-2xl mx-auto sm:pb-36 lg:pb-36  pb-16">
          <div className="flex flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap">
            <div className="flex-1 mx-auto lg:mr-0 max-w-2xl lg:max-w-xl  xl:max-w-2xl flex-shrink-0">
              <h1
                style={{ fontFamily: "MyCustomFont1" }}
                className="lg:text-left text-primary-900 text-white text-4xl sm:text-7xl lg:text-8xl font-black text-center"
              >
                PARTNERS
              </h1>
              <h2
                style={{ fontFamily: "MyCustomFont2" }}
                className="text-center lg:text-left text-white text-primary-900 leading-snug text-2xl sm:text-5xl font-black tracking-wide text-center"
              >
                MULTIPLAYER
              </h2>
              <p
                style={{ fontFamily: "MyCustomFont" }}
                className="uppercase mt-4 text-1sm sm:text-2sm md:text-base lg:text-lg font-medium leading-relaxed text-white max-w-xl mt-4 text-center lg:text-left lg:text-base text-white max-w-lg mx-auto lg:mx-0"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea com
              </p>
              <h2
                style={{ fontFamily: "MyCustomFont2" }}
                className="lg:mt-36 mt-16 sm:mt-36 text-center  lg:text-left text-primary-900 text-white leading-snug text-2xl sm:text-5xl font-black tracking-wide text-center"
              >
                CROSS-PLAT
              </h2>
              <p
                style={{ fontFamily: "MyCustomFont" }}
                className="mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed max-w-xl mt-4 text-center lg:text-left lg:text-base text-white max-w-lg mx-auto lg:mx-0"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea com
              </p>
            </div>
            <div className="flex-1 mx-auto lg:mr-0 relative mt-5 lg:mt-16 lg:mt-0 ml-10 lg:ml-8">
              <div
                className="flex flex-wrap"
                style={{
                  position: "relative",
                  transform: "translate(-10%,0)",
                }}
              >
                <img
                  src={Vector}
                  alt="Vector"
                  width={384}
                  height={565}
                  style={{ transform: "scale(1.05) translate(0,27%)" }}
                  className="w-1/2"
                />
                <img
                  src={heroScreenshotImageSrc}
                  style={{ transform: "scale(1.3) translate(-5%,15%) " }}
                  className="w-1/2"
                  alt="Hero"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sm:pt-24 md:pt-24  xl:pt-24 pt-8 text-center sm:pb-36 pb-16 bg-cover w-full h-full"
        style={{ backgroundImage: `url(${Section1})` }}
      >
        <div className="z-20 flex-1 justify-center relative max-w-screen-xl mx-auto">
          <div
            className="row text-center text-4xl sm:text-8xl lg:text-9xl font-black"
            style={{ color: "red", fontFamily: "MyCustomFont2" }}
          >
            TRAILER
          </div>
          <div className="sm:pb-24 mt-5 flex justify-ceter items-center">
            <div className="w-full">
              <ReactPlayer
                url="https://www.youtu.be/h8QF-kTfgvg?si=PugZSq8i4aisiesx"
                playing
                width="800px"
                height="450px"
                style={{margin: "auto"}}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-screen-2xl mx-auto ">
        <div className="flex flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 max-w-screen-2xl mx-auto flex-wrap">
          <div className="flex-1 mx-auto lg:mr-0 max-w-3xl lg:max-w-xl  xl:max-w-5xl flex-shrink-0">
            <div
              className="row text-center text-4xl sm:text-8xl lg:text-7xl font-black"
              style={{ color: "red", fontFamily: "MyCustomFont1" }}
            >
              LOREM
            </div>
          </div>
        </div>
      </div>
      <div
        className="pt-8 px-8 relative pb-16 bg-cover"
        style={{ backgroundImage: `url(${BackGround})` }}
      >
        <div className="max-w-screen-2xl mx-auto sm:pb-36 lg:pb-36  pb-16">
          <div className="flex flex-col lg:flex-row justify-between items-center max-w-screen-2xl mx-auto flex-wrap">
            <div className="flex-1 mx-auto lg:mr-0 max-w-3xl lg:max-w-xl  xl:max-w-5xl flex-shrink-0">
              <p
                style={{ fontFamily: "MyCustomFont" }}
                className="text-sm text-center md:text-base lg:text-lg font-medium leading-relaxed max-w-xl lg:text-base text-black max-w-lg mx-auto"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices g
              </p>
              <div className="sm:flex xl:flex lg:flex flex-warp">
                <div className="m-4 shadow-xl">
                  <div
                    style={{
                      backgroundColor: "rgba(181, 137, 97, 1)",
                      fontFamily: "MyCustomFont3",
                      borderRadius: "0px 40px 0px 0px",
                    }}
                    className="px-6 relative text-2xl sm:text-2xl lg:text-4xl py-3 text-black text-center"
                  >
                    LOREM
                  </div>
                  <div className="relative overflow-hidden bg-cover bg-no-repeat">
                    <img
                      style={{
                        marginTop: "-10px",
                      }}
                      className="rounded-t-lg w-full"
                      src={TeamIllustrationSrc1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="m-4 shadow-xl">
                  <div
                    style={{
                      backgroundColor: "rgba(181, 137, 97, 1)",
                      fontFamily: "MyCustomFont3",
                      marginTop: "5px",
                      borderRadius: "0px 40px 0px 0px",
                    }}
                    className="px-6 relative text-2xl sm:text-2xl lg:text-4xl py-3 text-black text-center"
                  >
                    LOREM
                  </div>

                  <div className="relative text-2xl sm:text-2xl lg:text-4xl overflow-hidden bg-cover bg-no-repeat">
                    <img
                      style={{
                        marginTop: "-5px",
                      }}
                      className="rounded-t-lg w-full"
                      src={TeamIllustrationSrc}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="sm:flex xl:flex lg:flex flex-warp mt-16">
                <div className="m-4 shadow-xl">
                  <div className="relative text-2xl sm:text-2xl lg:text-4xl overflow-hidden bg-cover bg-no-repeat">
                    <img
                      className="rounded-t-lg w-full"
                      src={TeamIllustrationSrc2}
                      alt=""
                    />
                    <h1
                      style={{ fontFamily: "MyCustomFont3" }}
                      className="absolute bottom-0 left-0 right-0 text-white p-2 text-center"
                    >
                      LOREM
                    </h1>
                  </div>
                </div>
                <div className="m-4 shadow-xl">
                  <div className="relative text-2xl sm:text-2xl lg:text-4xl overflow-hidden bg-cover bg-no-repeat">
                    <img
                      className="rounded-t-lg w-full"
                      src={TeamIllustrationSrc3}
                      alt="TeamIllustrationsSrc3"
                    />
                    <h1
                      style={{ fontFamily: "MyCustomFont3" }}
                      className="absolute bottom-0 left-0 right-0 text-white p-2 text-center"
                    >
                      LOREM
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="flex flex-col items-center bg-neutral-900 text-center text-white">
        <div className="container px-6 pt-6">
          <div className="flex justify-center flex-wrap">
            <Link
              to="#"
              style={{ fontFamily: "MyCustomFont4" }}
              type="button"
              className="m-1 rounded-full inline-block border-2 sm:text-sm border-white text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              White Paper
            </Link>
            <Link
              to="#"
              style={{ fontFamily: "MyCustomFont4" }}
              type="button"
              className="m-1 rounded-full inline-block border-2 text-sm sm:text-sm border-white leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              Pitch Deck
            </Link>
            <Link
              to="#"
              style={{ fontFamily: "MyCustomFont4" }}
              type="button"
              className="m-1 rounded-full inline-block border-2 text-sm sm:text-sm border-white leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              Privacy Policy
            </Link>
            <Link
              to="#"
              style={{ fontFamily: "MyCustomFont4" }}
              type="button"
              className="m-1 rounded-full inline-block border-2 text-sm sm:text-sm border-white leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              Shop
            </Link>
            <Link
              to="#"
              style={{ fontFamily: "MyCustomFont4" }}
              type="button"
              className="m-1 rounded-full inline-block border-2 text-sm sm:text-sm border-white leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              News
            </Link>
            <Link
              to="#"
              style={{ fontFamily: "MyCustomFont4" }}
              type="button"
              className="m-1 rounded-full inline-block border-2 text-sm sm:text-sm border-white leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              Web3
            </Link>
            <Link
              to="#"
              style={{ fontFamily: "MyCustomFont4" }}
              type="button"
              className="m-1 rounded-full inline-block border-2 text-sm sm:text-sm border-white leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              Epic
            </Link>
          </div>
          <div className="relative text-2xl sm:text-2xl lg:text-4xl overflow-hidden bg-cover bg-no-repeat">
            <img className="rounded-t-lg" src={Rectangle} alt="Reactangle" />
          </div>
        </div>
        <div className="container px-6 pt-6">
          <div className="mb-6 flex justify-center">
            <Link
              to="#"
              type="button"
              className="m-1 rounded-full border-2 border-white uppercase leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img src={Headra} className="rounded-t-lg w-full" alt="Hedera" />
            </Link>
            <Link
              to="#"
              type="button"
              className="m-1 rounded-full border-2 border-white uppercase leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img src={g4146} className="rounded-t-lg w-full" alt="xbox" />
            </Link>
            <Link
              to="#"
              type="button"
              className="m-1 rounded-full border-2 border-white uppercase leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img src={path} className="rounded-t-lg w-full" alt="path" />
            </Link>
            <Link
              to="#"
              type="button"
              className="m-1 rounded-full border-2 border-white uppercase leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img src={Steam_icon_logo} className="rounded-t-lg w-full" alt="Stream" />
            </Link>
            <Link
              to="#"
              type="button"
              className="m-1 rounded-full border-2 border-white uppercase leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img src={Epic_Games_logo} className="rounded-t-lg w-full" alt="Epic Games Logo" />
            </Link>
            <Link
              to="#"
              type="button"
              className="m-1 rounded-full border-2 border-white uppercase leading-normal text-black sm:pl-10 sm:pr-10 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <img src={Logo_Oculus} className="rounded-t-lg w-full" alt="Oculus logo" />
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing;