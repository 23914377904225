import { createContext, useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios"
import { HashConnect } from "hashconnect";
import { AnimatePresence } from "framer-motion";

import Marketplace from "./component/Marketplace/Marketplace";
import AppHeader from "./component/Navbar";
import Buy from "./component/Buy"
import Landing from "./component/Landing/landing";
import Mynft from "./component/Buy/mynft";
import Inventory from "./component/Banner/Inventory";
import "./App.css";

export const AccountContext = createContext(null); // save connected user info 
export const HaschconnectContext = createContext(null); // Save Hashconnect info
const uri = `https://testnet.mirrornode.hedera.com/api/v1`;
export const instance = axios.create({
  baseURL: uri,
});

function App() {

  const [accountId, setAccountId] = useState("") // accountId
  const [hash, setHash] = useState(new HashConnect(true)) // Hashconnnect(wallet connect)
  const [topic, setTopic] = useState("") // user wallet main info
  const [minted, setMinted] = useState(false); // When user click the buy button, its true
  const [weaponNfts, setWeaponNfts] = useState([]); // All Weapon NFTS
  const [size, setSize] = useState(0); // control iamge size and component with useRef
  const [balance, setBalance] = useState(0); // user wallet balance
  const [disconnect, setDisconnect] = useState(false); // wallet connect status(disconnect)

  // Get Balance Rest API
  const getBalance = useCallback(async () => {
    let balances = await instance.get(`balances?account.id=${accountId}`);
    setBalance(parseFloat(balances.data.balances[0].balance) / 100000000);
  }, [accountId]);
  console.log("balance" + balance)
  useEffect(() => {
    if (accountId) {
      getBalance();
    }
  }, [accountId, getBalance]);

  // Socket Connect with Unreal
  useEffect(() => {
    const ws = new WebSocket(`ws://localhost:9000`);
    ws.onopen = function () {
      if (ws.readyState === 1) {
        if (accountId.length <= 0 && !disconnect) {
          ws.send(["socket", "disconnected"]);
        } else if (accountId.length > 0) {
          ws.send([accountId, balance])
        }
      }
    };
    ws.onmessage = function (event) {
      if (accountId.length > 0) {
        ws.send([accountId, balance]);
      }
    };
    ws.onclose = function () {
    };
    return () => {
      ws.close();
    };
  }, [accountId, balance, disconnect]);

  return (
    <AnimatePresence>
      <div
        className="bg-white dark:bg-blue-black duration-300 transition"
      >
        <Router>
          {/* Connect Wallet */}
          <AccountContext.Provider value={{ accountId, setAccountId, setHash, topic, setTopic, disconnect, setDisconnect }}>
            <AppHeader />
          </AccountContext.Provider>

          {/* Body Componets */}
          <Routes>
            {/* Landing page */}
            <Route path="/" element={<Landing />} />
            {/* Marketplace page */}
            <Route path="/marketplace" element={<Marketplace hash={hash} topic={topic} accountId={accountId} minted={minted} setMinted={setMinted} setAccountId={setAccountId} weaponNfts={weaponNfts} setWeaponNfts={setWeaponNfts} size={size} setSize={setSize} />} />
            {/* Inventory Page */}
            <Route path="/Inventory" element={<Inventory accountId={accountId} setBalance={setBalance} weaponNfts={weaponNfts} setWeaponNfts={setWeaponNfts} size={size} setSize={setSize} />} />
            {/* Buy nfts page connect with Market place page */}
            <Route path="/buy/:tokenId/:ownerId/:name/:serialNumber" element={<Buy hash={hash} topic={topic} accountId={accountId} />} />
            {/* See NFT detail page in user wallet nfts connect with inventory */}
            <Route path="/inventory/:tokenId/:accountId/:name/:serialNumber" element={<Mynft />} />
          </Routes>
        </Router>
      </div>
    </AnimatePresence>
  );
}

export default App;