import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AccountId } from "@hashgraph/sdk";
import { FiSearch } from "react-icons/fi";
import { MetroSpinner } from "react-spinners-kit";
import { HaschconnectContext, instance } from "../../App";
import InventorySingle from "../Marketplace/InventorySingle";

const NftSlider = (props) => {
  const { weaponNfts, setWeaponNfts } = useContext(HaschconnectContext);
  const { setBalance } = props;

  const [walletnfts, setWalletNfts] = useState([]);
  const [currentNfts, setCurrentNfts] = useState([]);
  const [namesearch, setNameSearch] = useState("");
  const [contentsearch, setContentSearch] = useState("");
  const [selectType, setSelectType] = useState("");
  const [selectPriceType, setSelectPriceType] = useState("");
  const [send, setSend] = useState(false);
  const accountId = AccountId.fromString(props.accountId);

  let value = [],
    metadata = [];

  useEffect(() => {
    // Get NFTS from AccountId using Rest API
    const load = async () => {
      // if (walletnfts.length > 0) {
      // } else {
      let nftaccounts = await instance.get(`accounts/${accountId}/nfts`);
      let balances = await instance.get(`balances?account.id=${accountId}`);

      setBalance(parseFloat(balances.data.balances[0].balance) / 100000000);
      let nfts = nftaccounts.data.nfts;
      console.log(nfts);
      for (var i = 0; i < nfts.length; i++) {
        const currentvalue = nfts[i].metadata;
        const newValue = Buffer.from(currentvalue, "base64");
        const res = await fetch(`https://ipfs.io/ipfs/${newValue}`);
        const meta = await res.json();
        meta.image = meta.image.slice(7);
        value[i] = "https://ipfs.io/ipfs/" + meta.image;
        ///ogc
        meta.tokenId = "0.0.4512378";
        ///
        meta.serial_number = nfts[i].serial_number;
        metadata.push(meta);
      }
      console.log("value : ", value);
      console.log("metadata : ", metadata);

      setWalletNfts(value);
      setWeaponNfts(metadata);
      setCurrentNfts(metadata);
      // }
      // if (weaponNfts.length >= 0) {
      Sendnft(accountId, metadata);
      console.log("length" + weaponNfts.length);
      // }
    };
    load();
  }, [weaponNfts.length >= 0]);

  // When get nfts using Rest API from AccountId, send nft and wallet user info to backend
  const Sendnft = (accountId, metadata) => {
    console.log("1111metadata : ", metadata);
    console.log("walletnfts : ", walletnfts);
    var arr = {
      accountId: accountId.toString(),
      metadata: metadata,
    };
    if (send === false) {

      // return;
      ///ogc
      axios.post("https://v3market.net/users/nftsave", arr).then((res) => {
        if (res.data === "success")
          setSend(true);
        else if (res.data === "Already send")
          alert("Already send");
      })
    }
    return;
    axios.post("https://v3market.net/users/nftsave", arr).then((res) => {
      // axios.post("http://localhost:8000/users/nftsave", arr).then((res) => {
      // if (res.data === "success")
      // setSend(true);
      // else if (res.data === "Already send")
      //   alert("Already send");
    });
    // }
  };

  // NFT filetr

  function handleNameSearch(e) {
    setNameSearch(e.target.value);
  }

  function handleContentSearch(e) {
    setContentSearch(e.target.value);
  }

  function handleNameSearchSubmit() {
    console.log("handleNameSearchSUbmut", namesearch);
    let nfts = [];
    for (var i = 0; i < weaponNfts.length; i++) {
      let text = weaponNfts[i].name.toLowerCase();
      let position = text.search(namesearch.toLowerCase());
      console.log(position);
      if (position >= 0) {
        console.log("position");
        if (contentsearch === "") {
          console.log(selectType);
          if (selectType === "All" || selectType === "") {
            nfts.push(weaponNfts[i]);
          } else if (weaponNfts[i].weaponType === selectType) {
            nfts.push(weaponNfts[i]);
          }
        } else {
          let content = weaponNfts[i].description.toLowerCase();
          let contentposition = content.search(contentsearch.toLowerCase());
          if (contentposition >= 0) {
            if (selectType === "All" || selectType === "") {
              nfts.push(weaponNfts[i]);
            } else if (weaponNfts[i].weaponType === selectType) {
              nfts.push(weaponNfts[i]);
            }
          }
        }
      }
    }
    setCurrentNfts(nfts);
  }

  function handleContentSearchSubmit() {
    console.log("handleContentSearchSUbmit");
    let nfts = [];
    for (var i = 0; i < weaponNfts.length; i++) {
      let text = weaponNfts[i].description.toLowerCase();
      let position = text.search(contentsearch.toLowerCase());
      if (position >= 0) {
        if (namesearch === "") {
          if (selectType === "All" || selectType === "") {
            nfts.push(weaponNfts[i]);
          } else if (weaponNfts[i].weaponType === selectType) {
            nfts.push(weaponNfts[i]);
          }
        } else {
          let content = weaponNfts[i].name.toLowerCase();
          let contentposition = content.search(namesearch.toLowerCase());
          if (contentposition >= 0) {
            if (selectType === "All" || selectType === "") {
              nfts.push(weaponNfts[i]);
            } else if (weaponNfts[i].weaponType === selectType) {
              nfts.push(weaponNfts[i]);
            }
          }
        }
      }
    }
    setCurrentNfts(nfts);
  }

  function handleSelectTypeChange(event) {
    setNameSearch("");
    setContentSearch("");
    let nfts = [];
    for (var i = 0; i < weaponNfts.length; i++) {
      if (event.target.value === "All") {
        nfts.push(weaponNfts[i]);
      } else if (weaponNfts[i].weaponType === event.target.value) {
        nfts.push(weaponNfts[i]);
      }
    }
    setSelectType(event.target.value);
    setCurrentNfts(nfts);
  }

  function handlePriceSelectTypeChange(event) {
    if (event.target.value === "1")
      setCurrentNfts((weapons) =>
        [...weapons].sort((a, b) => a.price - b.price)
      );
    else
      setCurrentNfts((weapons) =>
        [...weapons].sort((a, b) => b.price - a.price)
      );

    setSelectPriceType(event.target.value);
  }

  return (
    <section className="mt-5 py-15  md:mx-40 sm:mx-20 mx-10 sm:mt-10 sm:py-10 ">
      {/* NFT lis filter bar  */}
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 border-b border-primary-light dark:border-gray-700 justify-between pb-3">
        <div className="flex w-full pl-7 mb-2 md:mb-5">
          <input
            className="font-general-medium w-full font-sans md:text-sm border border-slate-950 rounded-lg sm:text-[5px] bg-secondary-light text-primary-dark focus:outline-none w-1/4 pl-4"
            id="nftSearch"
            name="nftSearch"
            type="search"
            required=""
            placeholder="SEARCH NFT NAME..."
            aria-label="Name"
            value={namesearch}
            onChange={handleNameSearch}
            style={{ border: "1px solid black", height: "45px" }}
          />
          <span
            className="sm:block bg-primary-light dark:bg-ternary-dark p-2.5 shadow-sm  rounded-xlP cursor-pointer"
            onClick={handleNameSearchSubmit}
            style={{ marginLeft: "-52px", marginTop: "-11px" }}
          >
            <FiSearch
              className="dark:text-white h-10 text-ternary-dark w-8 mt-1"
              style={{ color: "#c5c6c6" }}
            ></FiSearch>
          </span>
        </div>
        <div className="flex w-full pl-7 mb-2 md:mb-5">
          <input
            className="font-general-medium w-full font-sans text-sm border border-slate-950 rounded-lg sm:text-md bg-secondary-light text-primary-dark focus:outline-none w-1/4 pl-4"
            id="nftSearch"
            name="nftSearch"
            type="search"
            required=""
            placeholder="SEARCH NFT CONTENT..."
            aria-label="Name"
            value={contentsearch}
            onChange={handleContentSearch}
            style={{ border: "1px solid black", height: "45px" }}
          />
          <span
            className="sm:block bg-primary-light dark:bg-ternary-dark p-2.5 shadow-sm  rounded-xlP cursor-pointer"
            onClick={handleContentSearchSubmit}
            style={{ marginLeft: "-52px", marginTop: "-11px" }}
          >
            <FiSearch
              className="dark:text-white h-10  w-8 mt-1"
              style={{ color: "#c5c6c6" }}
            ></FiSearch>
          </span>
        </div>
        <div className="flex w-full pl-7 pb-2.5 mb-2 md:mb-5">
          <select
            className="font-general-medium font-sans text-sm border border-slate-950 rounded-lg sm:text-md bg-secondary-light text-primary-dark focus:outline-none w-full pl-4"
            style={{
              backgroundImage:
                "url('https://marketplace.digital-arms.com/img/ep_arrow-right-bold.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "95% 50%",
              appearance: "none",
              border: "1px solid black",
              height: "45px",
            }}
            value={selectPriceType}
            onChange={handlePriceSelectTypeChange}
          >
            <option className="sm:text-md text-normal" value="0">
              PRICE: LOW TO HIGHT
            </option>
            <option className="sm:text-md text-normal" value="1">
              PRICE: HIGH TO LOW
            </option>
          </select>
        </div>

        <div className="flex w-full pl-7 mb-2 md:mb-5">
          <select
            className="font-general-medium  font-sans text-sm border border-slate-950 rounded-lg sm:text-md bg-secondary-light text-primary-dark focus:outline-none w-full pl-4"
            style={{
              backgroundImage:
                "url('https://marketplace.digital-arms.com/img/ep_arrow-right-bold.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "95% 50%",
              appearance: "none",
              border: "1px solid black",
              height: "45px",
            }}
            value={selectType}
            onChange={handleSelectTypeChange}
          >
            <option className="sm:text-md text-normal" value="All">
              ALL ITEMS
            </option>
            <option className="sm:text-md text-normal" value="Primary">
              PRIMARY
            </option>
            <option className="sm:text-md text-normal" value="Secondary">
              SECONDARY
            </option>
            <option className="sm:text-md text-normal" value="Special">
              SPECIAL
            </option>
            <option className="sm:text-md text-normal" value="Melee">
              MELEE{" "}
            </option>
            <option className="sm:text-md text-normal" value="Explosive">
              EXPLOSIVE
            </option>
          </select>
        </div>
      </div>
      <div className="grid grid-cols-1  lg:grid-cols-2 md:grid-cols-1 xl:grid-cols-3 2xl:grid-cols-4 mt-6 p-9 sm:gap-10 gap-10">
        {currentNfts.length > 0 ? (
          currentNfts.map((metadata, index) => (
            <InventorySingle
              creator={"@BrainStorm"}
              name={metadata.name}
              image={metadata.image}
              description={metadata.description}
              accountId={props.accountId}
              tokenId={process.env.REACT_APP_TOKENID}
              key={index}
              ownerId={metadata.tokenId}
              price={metadata.price}
              serialNumber={metadata.serial_number}
            />
          ))
        ) : (
          <div className="flex justify-center align=center mt-10 w-full">
            <MetroSpinner color="#00ff89" />
          </div>
        )}
      </div>
    </section>
  );
};
export default NftSlider;
