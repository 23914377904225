import React, { useEffect, useRef } from "react"
import { motion } from "framer-motion";

import NftSlider from "./NftSlider";
import { HaschconnectContext } from "../../App";  
import incoin from "../../assets/images/671.png"
import "./banner.css";

const Inventory = (props) => {
  const { weaponNfts, setWeaponNfts, setBalance, accountId, size, setSize } = props;

  // Get Image height and place component on the image by flowing the image size
  const targetRef = useRef();
  const marginTop = size * (-0.5);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { height } = entry.target.getBoundingClientRect();
        setSize(height)
      }
    })
    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [targetRef, setSize])

  return (
    <div className="">
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
        className="flex flex-col items-center md:mt-2mt-12 mx-autoborder-b dark:border-gray-700 border-gray-200p-4 absloute"
      >
        <img src={incoin} ref={targetRef} alt="inventory icom"  />
      </motion.section>
      {
        accountId ?
          <div style={{ marginTop: marginTop }}>
            <HaschconnectContext.Provider value={{ weaponNfts, setWeaponNfts }}>
              {/* NFT lists */}
              <NftSlider accountId={accountId} setBalance={setBalance} />
            </HaschconnectContext.Provider> </div> :
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.9,
              delay: 0.1,
            }}
            className="text-center text-ternary-10 dark:text-white text-xl text-xs pt-20 font-black"
            style={{ marginTop: marginTop }}
          >
            Please Connect Wallet First...
          </motion.h1>
      }
    </div>
  );

};

export default Inventory;