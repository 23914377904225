import Banner from "../Banner";

const Marketplace = (props) => {
  return (
    <div className="marketplace">
      {/* marketplace body */}
      <Banner hash={props.hash} topic={props.topic} accountId={props.accountId} weaponNfts={props.weaponNfts} size={props.size} setWeaponNfts={props.setWeaponNfts} setSize={props.setSize} />
    </div>
  );
};
  
export default Marketplace;
