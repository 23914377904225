import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react"
import coin from "../../assets/images/56752.png";
import HederaNFTGrid from "../Marketplace/HederaNFTGrid";
import "./banner.css";
import { HaschconnectContext } from "../../App";

const Banner = (props) => {

  const { hash, minted, setMinted, weaponNfts, setWeaponNfts, size, setSize } = props

  // Get Iamage height and place component on the image 
  const targetRef = useRef();
  const marginTop = size * (-0.4);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { height } = entry.target.getBoundingClientRect();
        setSize(height)
      }
    })
    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [targetRef, setSize])


  return (
    <div className="">
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
        className="flex flex-col items-center md:mt-2 mt-12 mx-auto border-b dark:border-gray-700 border-gray-200 p-4 absloute"
      >
        <img src={coin} ref={targetRef} alt="" />
      </motion.section>
      {
        // NFT inventory in user wallet
        <div style={{ marginTop: marginTop }}>
          <HaschconnectContext.Provider value={{ hash, minted, setMinted, weaponNfts, setWeaponNfts }}>
            <HederaNFTGrid accountId={props.accountId} topic={props.topic} />
          </HaschconnectContext.Provider> </div>
      }
    </div>
  );

};

export default Banner;