import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { AccountContext } from "../../App";
import { HashConnect } from "hashconnect";
import logoDark from "../../assets/images/v3-removebg-preview-12.png";
import axios from "axios";
let appMetadata = {
  name: "Unreal Integration NFT marketplace",
  description: "Unreal Integration NFT marketplace",
  icon: "https://ipfs.io/ipfs/QmWaYa4TdA67fmr2NrPeiincWFTeLEY7phKwND1pkneGVP"
}

let hashconnect = new HashConnect();

const Navbar = () => {
  const { accountId, setAccountId, setHash, topic, setTopic, disconnect, setDisconnect } = useContext(AccountContext);

  const [pairingString, setPairingString] = useState(null)
  useEffect(() => {
    function load() {
      if (localStorage.getItem("isWalletConnect") === "true") {
        connectHedera()
      }
    }
    load()
  })

  //Conenct HashPack Wallet
  async function connectHedera() {
    let initData = await hashconnect.init(appMetadata, "testnet", false);
    setPairingString(initData.pairingString)
    setTopic(initData.topic)
    if (localStorage.getItem("isWalletConnect") === "true") {
      let len = initData.savedPairings.length
      setAccountId(initData.savedPairings[len - 1].accountIds[0])
    } else {
      hashconnect.foundExtensionEvent.once((walletMetadata) => {
        console.log(initData.pairingString, walletMetadata)
        hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
      })
      hashconnect.pairingEvent.once((pairingData) => {
        localStorage.setItem("isWalletConnect", true);
        console.log(pairingData)
        setAccountId(pairingData.accountIds[0]);
      })
    }
    setHash(hashconnect)
  }

  // Diconnect HashPack Wallet
  async function Disconnect() {
    console.log(pairingString)
    await hashconnect.disconnect(topic);
    setPairingString(null)
    setDisconnect(!disconnect)
    localStorage.setItem("isWalletConnect", false);
    window.location.reload();
    setHash(hashconnect)
  }

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      id="nav"
      className="sm:container sm:mx-auto">
      <div className="grid grid-cols-4 py-2 sm:flex sm:items-center sm:justify-between xl:max-w-screen-xl z-10">
        <div className="flex items-center justify-between px-4 sm:px-0">
          <div>
            <Link to="/"><img src={logoDark} className="w-10 sm:w-20" alt="Light Logo" /></Link>
          </div>
        </div>
        <Link to="/marketplace"><h3 className="text-black text-xs sm:text-2xl font-bold text-right item-center justify-center md:pr-6 mt-4 " >MarketPlace</h3></Link>
        <Link to="/inventory"><h3 className="text-black text-xs sm:text-2xl font-bold text-right item-center justify-center pr-6 mt-4 sm:mr-8">Inventory</h3></Link>
        <div className="flex-col  items-center justify-between md:flex-row sm:flex">
          <div className=" w-20 h-5 mt-3 sm:flex sm:w-40 sm:h-10 sm:mt-4" style={{ transform: "skew(-35deg, 0deg)", backgroundColor: "#FF0000", alignItems: "center", justifyContent: "center" }}>
            {!accountId ? (
              <button className="flex font-bold text-white text-xs sm:text-lg text-center pt-0.5 px-5 " style={{ transform: "skew(35deg, 0deg)" }} onClick={connectHedera}>
                Wallet
              </button>
            ) : (
              <button
                className="flex font-bold text-white text-xs text-center px-1 pt-0.5"
                style={{ transform: "skew(35deg, 0deg)" }}
                onClick={Disconnect}
              >
                {accountId.toString()}
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

export default Navbar;
