import { motion } from "framer-motion";
import { Link } from "react-router-dom";


const InventorySingle = ({name, image, accountId, tokenId, price, serialNumber}) => {
    return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, delay: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.7,
            delay: 0.15,
          }}
          style={{ border: "1px solid #313f4a", borderRadius: "10px", boxShadow: "2px 2px 5px #00000040", padding: "20px 15px", position: "relative" }}
        >
          <div
            className="bg-secondary-light cursor-pointer hover:shadow-xl mb-10 mr-4 ml-4 rounded-xl shadow-lg sm:mb-0 m-1"
          >
            <Link
              to={`/inventory/` + tokenId + `/` + accountId + `/` + name + `/` + serialNumber }
              aria-label="Single Project"
            >
              <div className="mt-16">
                <img
                  src={"https://ipfs.io/ipfs/" + image}
                  className="2xl:h-72 h-36 lg:h-44 md:h-48 object-cover rounded-xl sm:h-62 w-full xl:h-56"
                  alt="Single Project"
                />
              </div>
              <div className="px-4 py-2">
                <p
                  className="dark:text-white font-bold font-general-medium mb-3 mt-3 md:text-xl text-center lg:text-2xl text-ternary-dark text-lg"
                >
                  {name}
                </p>
                <div className="text-black text-center lg:text-xl mb-2">
                  {price}HBAR
                </div>
              </div>
            </Link>
            </div>
        </motion.div>
      );
}

export default InventorySingle;