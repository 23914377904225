import { motion } from "framer-motion";
import axios from "axios"
import { useContext } from "react";
import { Link } from "react-router-dom";
import { HaschconnectContext } from "../../App";
import { AccountId, PrivateKey, Client, TransactionId, TransactionReceipt, TransferTransaction, Hbar, TokenAssociateTransaction } from "@hashgraph/sdk";


const tokenId = process.env.REACT_APP_TOKENID; // KOMMANDO_WEAPONS
const ownId =  process.env.REACT_APP_OWNERID;
const ownerPvKey = process.env.REACT_APP_OWNERPVKEY
const clientId = process.env.REACT_APP_CLIENTID
const privateKey = process.env.REACT_APP_CLIENTPVKEY

const uri = `https://testnet.mirrornode.hedera.com/api/v1`;
const instance = axios.create({
  baseURL: uri,
});

const operatorId = AccountId.fromString(clientId);
const operatorKey = PrivateKey.fromString(privateKey);
const OwnerKey = PrivateKey.fromString(ownerPvKey)
const client = Client.forTestnet().setOperator(operatorId, operatorKey);
// client.setDefaultMaxTransactionFee(new Hbar(40));
const NftSingle = ({ name, image, accountId, topic, cid, serialNumber, price, setCurrentNfts }) => {
  const { hash } = useContext(HaschconnectContext);
  // check this nft is already associated
  async function isNFTAssociatedWithWallet(tokenId, accountId) {
    let check = false
    try {
      let balance = await instance.get(`balances?account.id=${accountId}`);
      const tokens = balance.data.balances[0].tokens;
      for (var i = 0; i < tokens.length; i++) {
        if (tokens[i].token_id === tokenId) check = true

      }
      return check
    } catch (err) {
    }
  }


  // when user click the buy button >- buy.
  async function handleClickMint(cid) {
    console.log(tokenId , accountId)
    const isAssociated = await isNFTAssociatedWithWallet(tokenId, accountId)
    if (isAssociated) {
      // nft is already Associated
      const transferTx = transferHbar(accountId, ownId);
      try {
        const hbartransferReponse = await sendTransaction(transferTx);
        console.log(hbartransferReponse)
        if (hbartransferReponse) {
          const Tx = TransactionId.generate(ownId)
          const transferNft = await new TransferTransaction()
            .setTransactionId(Tx)
            .addNftTransfer(tokenId, serialNumber, ownId, accountId)
            .setNodeAccountIds([new AccountId(3)])
            .freeze(client)
            .sign(OwnerKey)

          try {
            const transferNftResponse = await sendTransaction(transferNft)
            if (transferNftResponse) {
              let nftaccounts = await instance.get(`accounts/${ownId}/nfts`);
              let nft = nftaccounts.data.nfts;
              let metadata = [];
              for (var i = 0; i < nft.length; i++) {
                const currentvalue = nft[i].metadata;
                const newValue = Buffer.from(currentvalue, "base64");
                const res = await fetch(`https://ipfs.io/ipfs/${newValue}`);
                const meta = await res.json();
                meta.serial_number = nft[i].serial_number;
                metadata.push(meta);
              }
              setCurrentNfts(metadata)
            }
          } catch (err) {
            console.log(err);
            const transferReTx = transferHbar(ownId, accountId);
            try {
              const hbarRetransferResponse = await sendTransaction(transferReTx);
              console.log(hbarRetransferResponse)
            } catch (err) {
              console.log(err)
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      // nfts is not Associated yet
      const associateTx = associateNFT(accountId);

      try {
        const associateNFTResponse = await sendTransaction(associateTx);
        if (associateNFTResponse) {
          const transferTx = transferHbar(accountId, ownId);
          try {
            const hbartransferReponse = await sendTransaction(transferTx);
            if (hbartransferReponse) {
              // Transfer NFT from Owner wallet to User.
              const Tx = TransactionId.generate(ownId)
              const transferNft = await new TransferTransaction()
                .setTransactionId(Tx)
                .addNftTransfer(tokenId, serialNumber, ownId, accountId)
                .setNodeAccountIds([new AccountId(3)])
                .freeze(client)
                .sign(OwnerKey)

              try {
                const transferNftResponse = await sendTransaction(transferNft)
                if (transferNftResponse) {
                  let nftaccounts = await instance.get(`accounts/${ownId}/nfts`);
                  let nft = nftaccounts.data.nfts;
                  let metadata = [];
                  for (i = 0; i < nft.length; i++) {
                    const currentvalue = nft[i].metadata;
                    const newValue = Buffer.from(currentvalue, "base64");
                    const res = await fetch(`https://ipfs.io/ipfs/${newValue}`);
                    const meta = await res.json();
                    meta.serial_number = nft[i].serial_number;
                    metadata.push(meta);
                  }
                  setCurrentNfts(metadata)
                }
              } catch (err) {
                console.log(err);
                const transferReTx = transferHbar(ownId, accountId);
                try {
                  const hbarRetransferResponse = await sendTransaction(transferReTx);

                  console.log(hbarRetransferResponse)
                } catch (err) {
                  console.log(err)
                }
              }
            }
          } catch (err) {
            console.loge(err)
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
  }



  // Send Transaction for hbar buyer to owner
  async function sendTransaction(tx, sign = false) {
    let response, hashConnectTxBytes;
    console.log(tx)
    hashConnectTxBytes = sign ? (
      makeBytes(tx, accountId)
    ) : (
      tx.toBytes()
    );

    response = await hash?.sendTransaction(
      topic,
      {
        topic: topic,
        byteArray: hashConnectTxBytes,
        metadata: {
          accountToSign: accountId,
          returnTransaction: false,
        }
      }
    )

    if (response?.receipt) {
      return TransactionReceipt.fromBytes(
        response.receipt
      )
    } else {
      throw new Error("No transaction recipt found")
    }
  }

  // get user sign
  async function makeBytes(trans, signingAcctId) {
    const transId = TransactionId.generate(signingAcctId);

    trans.setTransactionId(transId);
    trans.setNodeAccountIds([new AccountId(3)]);

    await trans.freeze(client);

    const transBytes = trans.toBytes();

    return transBytes
  }

  //HBAR transfer
  function transferHbar(accountId, ownId) {
    const txID = TransactionId.generate(accountId);

    const transferTransaction = new TransferTransaction()
      .setTransactionId(txID)
      .addHbarTransfer(accountId, new Hbar(-price))
      .addHbarTransfer(ownId, new Hbar(price))
      .setNodeAccountIds([new AccountId(3)])
      .freeze(client)
    return transferTransaction;
  }

  //NFT Associate
  function associateNFT(accountId) {
    // Set the expiration time to a future timestamp (in seconds)
    const expirationTime = Math.round(Date.now() / 1000) + 120; // 2 minutes from now
  
    const txID = TransactionId.generate(accountId)
      .setTransactionValidDuration(120) // 2 minutes
      .setTransactionValidStart({ seconds: expirationTime - 120 }); // Set the start time

    const tokenIds = [tokenId];
    console.log(tokenIds)
    const associateTransaction = new TokenAssociateTransaction()
      .setTransactionId(txID)
      .setTokenIds(tokenIds)
      .setAccountId(accountId)
      .setNodeAccountIds([new AccountId(3)])
      .freeze(client);

    return associateTransaction;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, delay: 1 }}
      transition={{
        ease: "easeInOut",
        duration: 0.7,
        delay: 0.15,
      }}
      style={{ border: "1px sol id #313f4a", borderRadius: "10px", boxShadow: "2px 2px 5px #00000040", padding: "20px 15px", position: "relative" }}
    >
      <div
        className="bg-secondary-light cursor-pointer hover:shadow-xl mb-10 mr-4 ml-4 rounded-xl shadow-lg sm:mb-0 m-1"
      >
        <Link
          to={`/buy/` + tokenId + `/` + ownId + `/` + name + `/` + serialNumber}
          aria-label="Single Project"
        >
          <div className="mt-16">
            <img
              src={"https://ipfs.io/ipfs/" + image}
              className="2xl:h-72 h-36 lg:h-44 md:h-48 object-cover rounded-xl sm:h-62 w-full xl:h-56"
              alt="Single Project"
            />
          </div>
          <div className="px-4 py-2">
            <p
              className="dark:text-white font-bold font-general-medium mb-3 mt-3 md:text-xl text-center lg:text-2xl text-ternary-dark text-lg"
            >
              {name}
            </p>
            <div className="text-black text-center lg:text-xl mb-2">
              {price}HBAR
            </div>
          </div>
        </Link>
        {accountId ? (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.9,
              delay: 0.1,
            }}
            style={{width: "100%", borderRadius: "10px", display: "flex", fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "22px", padding: "10px 30px", textAlign: "center", textShadow: "0 0 4px #0006", backgroundColor: "#080810", alignItems: "center", justifyContent: "center"
            }}
            onClick={() => handleClickMint(cid)}
          >
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                ease: "easeInOut",
                duration: 0.9,
                delay: 0.1,
              }}
              className="font-general-regular text-center text-white font-black mt-1"
            >
              BUY NOW
            </motion.h1>
          </motion.button>
        ) : (
          <></>
        )}

      </div>

    </motion.div>
  );
};

export default NftSingle;
