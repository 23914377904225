import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";

import PropertiesCard from "./propertiesCard";
import Card from "./nftCard";
import "./font.css";
import BorrowArrow from "../../assets/images/Rectangle.png";
import Price from "../../assets/images/pricelogo.png";
import Sixsense from "../../assets/images/Sixsense.png";
import footerback from "../../assets/images/DOT1.png";

const useStyles = makeStyles((theme) => ({
  tabs: {
    minHeight: "300px",
  },
  indicator: {
    backgroundColor: "red", // Customize the color of the tab underline here
    height: "6px",
  },
}));

const Mynft = (props) => {
  const classes = useStyles();

  const [nft, setNft] = useState(null);
  const [value, setValue] = useState(1);
  const [propertiy] = useState(4);
  const { tokenId, accountId, name, serialNumber } = useParams();

  //Get user Inventory with AccountId and tokenID
  useEffect(() => {
    const arr = {
      tokenId: tokenId,
      accountId: accountId,
      name: name,
    };
    console.log("tokenId " + tokenId, "accountID" + accountId);
    const getNFT = async () => {
      axios
        .post("https://v3market.net/users/GetInventory", arr)
        .then((res) => {
          // axios.post("http://localhost:8000/users/GetInventory", arr).then(res => {
          setNft(res.data[0].metadata);
        });
    };

    getNFT();
  }, [tokenId, accountId, name]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  if (nft === null) {
    return <></>;
  } else {
    return (
      <div>
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
          className="container mx-auto mt-7"
        >
          <Link to="/inventory">
            <img src={BorrowArrow} alt="Brrow Arrow" />
          </Link>

          <div className="grid lg:grid-cols-2 md:grid-cols-1 mt-6 sm:gap-4 sm:grid-cols-1 mb-20">
            <div className="flex justify-center items-center">
              <Card>
                <img
                  src={"https://ipfs.io/ipfs/" + nft.image}
                  alt="nft img"
                  className="shadow-2xl "
                  style={{
                    width: "300px",
                    height: "450px",
                    objectFit: "cover",
                    clipPath:
                      "polygon(0 0, 100% 0, 100% 90%, 90% 100%, 0 100%)",
                  }}
                />
              </Card>
            </div>
            <div className="lg:w-3/4 px-10 sm:px-0">
              <div
                className="text-5xl pt-10 font-semibold"
                style={{ color: "black", fontFamily: "nftname" }}
              >
                {nft.name.toUpperCase()}
              </div>
              <div
                className="font-general-regular sm:text-left text-black text-md font-medium pt-3"
                style={{
                  fontFamily: "nftdescription",
                  fontSize: "24px",
                  lineHeight: "37px",
                }}
              >
                {nft.description.toUpperCase()}
              </div>
              <div className="font-general-regular text-left text-white text-sm sm:text-xl pt-10 font-bold">
                Price
              </div>
              <div className="flex h-20">
                <img src={Price} className="mr-5" alt="Price Logo" />
                <h1
                  className="text-5xl font-semibold pt-3"
                  style={{ color: "black", fontFamily: "nftname" }}
                >
                  {nft.price}
                </h1>
              </div>
            </div>
          </div>

          <Tabs
            value={value}
            variant="fullWidth"
            onChange={handleTabChange}
            TabIndicatorProps={{
              className: classes.indicator,
            }}
          >
            <Tab
              value={1}
              label={
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  BUNDLE
                </Typography>
              }
            />
            <Tab
              value={2}
              label={
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  PROPERTIES
                </Typography>
              }
            />
          </Tabs>
          {value === 1 && (
            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 sm:gap-10 bg-black pt-16 pb-16 lg:pl-32 px-20">
              <div className="sm:pb-1 pb-10">
                <div
                  className=" text-white text-4xl font-black pb-2"
                  style={{ fontFamily: "nftname", fontWeight: "300px" }}
                >
                  {nft.name.toUpperCase()}
                </div>
                <div
                  className="font-general-regular sm:text-left text-white text-white text-lg sm:text-md"
                  style={{ fontFamily: "nftdescription" }}
                >
                  {nft.description.toUpperCase()}
                </div>
              </div>
              <div className="sixsense">
                <img
                  src={Sixsense}
                  className="w-80 sm:h-32 h-20"
                  style={{ width: "300px", height: "120px" }}
                  alt="SixSense"
                />
              </div>
            </div>
          )}
          {value === 2 && (
            <div
              className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 p-10 "
              style={{
                backgroundImage: `url(${footerback})`,
                backgroundColor: "black",
              }}
            >
              <div className="grid grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 py-3 sm:gap-2 sm:grid-cols-4  pt-8 pb-8">
                {propertiy === 0 && (
                  <>
                    <button active size="large" shape="default" />
                    <button active size="large" shape="default" />
                    <button active size="large" shape="default" />
                    <button active size="large" shape="default" />
                  </>
                )}
                <PropertiesCard
                  propertiy={"WeaponType"}
                  value={nft.weaponType}
                  key={1}
                />
                <PropertiesCard
                  propertiy={"Control"}
                  value={nft.Control}
                  key={2}
                />
                <PropertiesCard propertiy={"RPM"} value={nft.RPM} key={3} />
                <PropertiesCard propertiy={"Range"} value={nft.Range} key={4} />
              </div>
              <div className="px-8 sm:px-30 md:px-40 lg:px-20 ">
                <div className="font-general-regular text-left text-white text-sm sm:text-xl pt-10 font-bold">
                  Details
                </div>
                <div className="grid md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 grid-cols-2">
                  <div className="font-general-regular text-left text-white text-xs sm:text-xs pt-3 font-bold">
                    Token Id :{" "}
                  </div>
                  <div className="font-general-regular text-left text-white text-xs sm:text-xs pt-3 font-bold">
                    <Link
                      to={`https://hashscan.io/testnet/token/${tokenId}`}
                      className="text-blue-500 dark:text-blue-500 dark:hover:text-blue-300"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {tokenId}
                    </Link>
                  </div>
                  <div className="font-general-regular text-left text-white text-xs sm:text-xs pt-3 font-bold">
                    SerialNumber :{" "}
                  </div>
                  <div className="font-general-regular text-left text-white text-xs sm:text-xs pt-3 font-bold">
                    {serialNumber}
                  </div>
                  <div className="font-general-regular text-left text-white text-xs sm:text-xs pt-3 font-bold">
                    Token Standard :
                  </div>
                  <div className="font-general-regular text-left text-white text-xs sm:text-xs pt-3 font-bold">
                    HTS
                  </div>
                  <div className="font-general-regular text-left text-white text-xs sm:text-xs pt-3 font-bold">
                    Blockchain :
                  </div>
                  <div className="font-general-regular text-left text-white text-xs sm:text-xs pt-3 font-bold">
                    Hedera
                  </div>
                </div>
              </div>
            </div>
          )}
        </motion.section>
      </div>
    );
  }
};

export default Mynft;
